import styles from './button.module.scss'
import PropTypes from 'prop-types'
import React from 'react'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'

const Button = (props) => {
  const { i18n } = useTranslation()
  const onClick = () => {
    if (props.hasOwnProperty('onClick')) {
      props.onClick()
    }
  }
  return (
    <>
      {!props.hasOwnProperty('href') && (
        <button
          className={`${styles.button} ${
            props.hasOwnProperty('size') ? styles[props.size] : ''
          } ${props.hasOwnProperty('className') ? props.className : ''}
      ${props.hasOwnProperty('color') ? styles[props.color] : styles.green} ${
            props.hasOwnProperty('type') ? styles[props.type] : ''
          }`}
          style={{ width: props.hasOwnProperty('width') ? props.width : '80%' }}
          onClick={onClick}
          disabled={props.disabled}
          title={props.disabled && props.toolTip ? props.toolTip : ''}
        >
          {props.children}
        </button>
      )}
      {props.hasOwnProperty('href') && !props.disabled && (
        <Link href={props.href} locale={i18n.language}>
          <a
            className={`${styles.button} ${
              props.hasOwnProperty('size') ? styles[props.size] : ''
            }
      ${props.hasOwnProperty('color') ? styles[props.color] : styles.green} ${
              props.hasOwnProperty('type') ? styles[props.type] : ''
            } ${props.disabled ? 'button-disabled' : ''}`}
            style={{
              width: props.hasOwnProperty('width') ? props.width : '80%',
            }}
          >
            {props.children}
          </a>
        </Link>
      )}
    </>
  )
}
Button.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  toolTip: PropTypes.string,
}
export default Button
