import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

import Container from './grid/container'
import Link from 'next/link'
import Row from './grid/row'
import Column from './grid/column'
import Logo from '../svg/LogoFooter.svg'
import LogoSmall from '../svg/LogoSmall.svg'
import ScrollToTop from '../svg/scroll-to-top.svg'
import styles from './footer.module.scss'
import { useTranslation } from 'next-i18next'
import { gql, useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { SettingsContext } from '../../lib/context/SettingsContext'
import CookieConsent from '../cookie-consent'

const Footer = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const [mobile, setMobile] = useState(true)
  const { navigation } = useContext(SettingsContext)
  const copyrightDate = new Date()

  useEffect(() => {
    const isMobile = () => {
      setMobile(window.outerWidth <= 767)
    }
    if (window) {
      isMobile()
      window.addEventListener('resize', isMobile)
    }
    return () => {
      window.removeEventListener('resize', isMobile)
    }
  }, [])

  const scrollToTop = () => {
    if (window) {
      window.scroll(0, 0)
    }
  }
  return (
    <>
      <footer className={'footer'}>
        <div className={'scroll-to-top'} onClick={scrollToTop}>
          <ScrollToTop />
        </div>
        <div className={styles.footerMain}>
          <Container>
            <div className="md:flex md:flex-row">
              <Column>
                <div className={'logo'}>
                  {!mobile && (
                    <Link href={'/'}>
                      <a className="block">
                        <Logo />
                      </a>
                    </Link>
                  )}
                  {mobile && (
                    <Link href={'/'}>
                      <a>
                        <LogoSmall />
                      </a>
                    </Link>
                  )}
                </div>
                <p className={'copyright'}>
                  &copy; Copyright Wellness Traveller Ltd{' '}
                  {copyrightDate.getFullYear() > 2022
                    ? `2022 - ${copyrightDate.getFullYear()}`
                    : '2022'}
                </p>
              </Column>
              <Column grow={true}>
                <nav className={'footerNav'}>
                  {navigation && (
                    <ul>
                      {navigation
                        .filter((menu) => {
                          return menu.Location === 'Footer'
                        })[0]
                        .menuItems.map((item, i) => {
                          return (
                            <li key={i}>
                              <Link
                                href={item.url}
                                as={item.url}
                                locale={router.locale}
                              >
                                {item.title}
                              </Link>
                            </li>
                          )
                        })}
                    </ul>
                  )}
                </nav>
              </Column>
              <Column>
                <div className={'social'}>
                  <ul>
                    <li>
                      <a
                        href={'https://www.facebook.com/wellnesstraveller.co'}
                        target={`_blank`}
                      >
                        <FontAwesomeIcon icon={faFacebook} />
                        <span>Facebook</span>
                      </a>
                    </li>
                    {/*<li>*/}
                    {/*  <a href={'#'} target={`_blank`}>*/}
                    {/*    <FontAwesomeIcon icon={faTwitter} />*/}
                    {/*    <span>Twitter</span>*/}
                    {/*  </a>*/}
                    {/*</li>*/}
                    <li>
                      <a
                        href={'https://www.instagram.com/wellnesstraveller.co/'}
                        target={`_blank`}
                      >
                        <FontAwesomeIcon icon={faInstagram} />
                        <span>Instagram</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </Column>
            </div>
          </Container>
        </div>
        {process.env.NEXT_PUBLIC_SHOW_LANGUAGE === 'true' && (
          <div className={styles.footerLanguage}>
            <Container>
              <Row>
                <Column grow>
                  <ul>
                    <li className={router.locale === 'en' ? 'active' : ''}>
                      <Link href="/" locale="en">
                        English
                      </Link>
                    </li>
                    <li className={router.locale === 'es' ? 'active' : ''}>
                      <Link href="/" locale="es">
                        Español
                      </Link>
                    </li>
                    <li className={router.locale === 'de' ? 'active' : ''}>
                      <Link href="/" locale="de">
                        Deutsch
                      </Link>
                    </li>
                    <li className={router.locale === 'fr' ? 'active' : ''}>
                      <Link href="/" locale="fr">
                        Français
                      </Link>
                    </li>
                  </ul>
                </Column>
              </Row>
            </Container>
          </div>
        )}
      </footer>
      <CookieConsent />
    </>
  )
}
export default Footer
