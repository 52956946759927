import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faUser } from '@fortawesome/pro-regular-svg-icons'
import { faUser as faUserSolid } from '@fortawesome/pro-solid-svg-icons'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { useCookies } from 'react-cookie'
import { UserContext } from '../../lib/context/UserContext'
import LoginPopup from '../popups/login'
import CreateAccountPopup from '../popups/create-account'
import styles from './menu.module.scss'
import AccountPopup from '../popups/account'
import Popup from '../popup'
import Link from 'next/link'
import { useRouter } from 'next/router'
import ApiContext from '../../utils/api-context'
import { useModal } from '../../lib/context/modal-context'
import MessageOverlay from '../overlays/hotel-pre-order'
import Image from 'next/image'

const LoginMenu = ({ alt = false }) => {
  const { t, i18n } = useTranslation()
  const router = useRouter()
  const [loggedIn, setLoggedIn] = useState(false)
  const [loginOpen, setLoginOpen] = useState(false)
  const [createAccountOpen, setCreateAccountOpen] = useState(false)
  const [accountOpen, setAccountOpen] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies(['jwt', 'user'])
  const { user, logoutCurrentUser } = useContext(UserContext)
  const { apiUrl, cdnUrl } = useContext(ApiContext)
  const { setModal, modal } = useModal()
  useEffect(() => {
    if (cookies.hasOwnProperty('jwt') && cookies.jwt !== '') {
      setLoggedIn(true)
    }
    // return
  }, [cookies])

  const showLoginBox = () => {
    setModal({
      title: 'Welcome back!',
      content: (
        <LoginPopup
          onClose={() => setAccountOpen(true)}
          // onCreateAccount={() => setCreateAccountOpen(true)}
        />
      ),
    })
  }
  const doLogout = () => {
    logoutCurrentUser()
    window.location.href = '/'
    // router.push('/')
  }
  const checkPopup = () => {
    if (!alt && window && window.innerWidth > 981) {
      setAccountOpen(true)
    } else {
      router.push('/account')
    }
  }
  return (
    <>
      {!loggedIn && (
        <>
          <div className={styles.login} onClick={showLoginBox}>
            <FontAwesomeIcon icon={faUser} />
            <span>{t('login')}</span>
          </div>
        </>
      )}
      {loggedIn && (
        <>
          <div className={styles.login} onClick={checkPopup}>
            {!user.profileImage && <FontAwesomeIcon icon={faUserSolid} />}
            {user.profileImage && (
              <div className="profile-image">
                {user.profileImage.formats && (
                  <Image
                    src={`${user.profileImage.formats.thumbnail.url}`}
                    width={20}
                    height={20}
                    alt="Profile"
                    layout={'fixed'}
                  />
                )}
                {!user.profileImage.formats && (
                  <Image
                    src={`${user.profileImage.url}`}
                    width={20}
                    height={20}
                    alt="Profile"
                    layout={'fixed'}
                  />
                )}
              </div>
            )}
            <span
              style={{
                color: alt || window.innerWidth < 981 ? '#666666' : 'white',
                fontWeight: 500,
                cursor: 'pointer',
              }}
            >
              {t('account')}
            </span>
          </div>
        </>
      )}
      {accountOpen && (
        <Popup
          onClose={() => setAccountOpen(false)}
          header={t('account')}
          style={{
            // left: '50%',
            // transform: 'translateX(-50%)',
            width: 310,
          }}
          position={'center'}
        >
          <div className={`${styles.accountNavigation}`}>
            <nav className={styles.menu}>
              <ul>
                <li>
                  <Link href={'/account/bookings'} locale={i18n.language}>
                    My Bookings
                  </Link>
                </li>
                <li>
                  <Link href={'/account/messages'} locale={i18n.language}>
                    Messages
                  </Link>
                </li>
                <li>
                  <Link href={'/account/wishlist'} locale={i18n.language}>
                    Wish List
                  </Link>
                </li>
              </ul>
            </nav>
            <nav className={styles.secondary}>
              <ul>
                <li>
                  <Link href={`/account`} locale={i18n.language}>
                    Account Settings
                  </Link>
                </li>
                <li>
                  <Link href={`/faqs`} locale={i18n.language}>
                    FAQs
                  </Link>
                </li>
                <li>
                  <span className="cursor-pointer" onClick={doLogout}>
                    {t('logout')}
                  </span>
                </li>
                <li>
                  <Link href={`/contact-us`} locale={i18n.language}>
                    {t('contact-us')}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </Popup>
      )}
    </>
  )
}
export default LoginMenu
