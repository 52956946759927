import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { cookieConsent } from '../lib/api'
import Link from 'next/link'
import config from '../config'

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(['_consent'])
  const [accepted, setAccepted] = useState(false)
  const [consentData, setConsentData] = useState(null)
  const date = new Date()
    date.setDate(date.getDate() + 30)
    console.log(date)

  const acceptCookies = () => {
    const date = new Date()
    setCookie('_consent', 'yes', {
      ...config.cookieOptions,
      expires: new Date(date.setDate(date.getDate() + 30)),
    })
    setAccepted(true)
  }
  useEffect(() => {
    //
    if (!accepted) {
      if (!cookies.hasOwnProperty('_consent')) {
        cookieConsent().then((data) => {
          console.log(data)
          setConsentData(data)
          //@ts-ignore
          document.body.style.overflowY = 'hidden'
        })
      } else {
        setAccepted(true)
      }
    } else {
        document.body.style.overflowY = 'auto'
    }
  }, [accepted])
  return (
    <>
      {!accepted && consentData && (
        <div className="fixed top-0 left-0 w-screen h-screen bg-blue/60 z-50">
          <div className="fixed left-0 bottom-0 w-full bg-white flex flex-row justify-between items-center px-4 py-2">
            <p className="font-semibold">
              {consentData.bannerText}{' '}
              {consentData.cookiePage && (
                <Link href={`/static-page/${consentData.cookiePage.slug}`}>
                  Learn More
                </Link>
              )}
            </p>
            <button className="button primary" onClick={acceptCookies}>
              Accept
            </button>
          </div>
        </div>
      )}
    </>
  )
}
export default CookieConsent
